.content {
  a {
    text-decoration: underline;
  }
  img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  strong {
    font-width: bold;
  }
  em {
    font-style: italic;
  }
  p {
    font-family: $font-family-base;
    line-height: 1.56;
    color: $steel;
  }
  h1 {
    font-family: $font-family-heading;
    font-size: 34px;
    line-height: 1.26;
    font-weight: normal;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  h2 {
    font-family: $font-family-heading;
    font-size: 26px;
    font-weight: normal;
    line-height: 1.4;
    margin-top: 30px;
  }
  h3 {
    font-family:  $font-family-heading;
    font-size: 20px;
    line-height: 1.4;
    font-weight: normal;
    margin-top: 30px;
  }
  ul,
  ol {
    margin-bottom: 20px;
    ul,
    ol {
      margin-bottom: 0;
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  li {
    margin-bottom: 5px;
    line-height: 1.56;
    margin-left: 20px;
    color: $steel;
  }
  hr {
    border: none;
    border-bottom: 1px solid $white-offset;
  }
  table {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    th,
    td {
      padding: 5px;
      vertical-align: top;
      border-top: 1px solid $white-offset;
    }
    thead th {
      vertical-align: bottom;
      border-bottom: 1px solid $white-offset;
      text-align: left;
      font-weight: bold;
    }
    tbody + tbody {
      border-top: 1px solid $white-offset;
    }
  }
  blockquote {
    padding: 0 1em;
    color: $steel;
    border-left: .25em solid $secondary;
  }
}
